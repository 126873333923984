import React from "react";
import Badge from "../DataDisplay/Badge";
import UserImg from "../DataDisplay/UserImg";
import { Avatar } from "primereact/avatar";


export const DetailItem = ({ label, value, link, setValue }) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="mt-4 flex align-items-flex-start justify-content-left" >
      <h6 className="mt-0 mb-0 mr-1" style={{ fontSize: "15px", width: "fit-content" }}>
        {label}
      </h6>
      {link ? (
        <a href={link} target="_blank">
          {label === "Description : " ? (
            <textarea
              style={{
                fontSize: "15px",
                width: "90%",
                border: "none",
                resize: "none",
                overflow: "hidden",
                height: "auto",
              }}
              value={value}
              onChange={handleChange}
            />
          ) : (
            <input
              type={label === "Stock : " ? "number" : "text"}
              style={{
                fontSize: "15px",
                width: "90%",
                border: "none",
                background: "transparent",
              }}
              value={value}
              onChange={handleChange}
            />
          )}
        </a>
      ) : (
        <>
          {label === "Description : " ? (
            <textarea
              style={{
                fontSize: "15px",
                width: "90%",
                border: "none",
                background: "transparent",
                resize: "none",
                overflow: "hidden",
                height: "100px",
              }}
              value={value}
              onChange={handleChange}
            />
          ) : (
            <input
              type={label === "Stock : " ? "number" : "text"}
              style={{
                fontSize: "15px",
                width: "90%",
                border: "none",
                background: "transparent",
              }}
              value={value}
              onChange={handleChange}
            />
          )}
        </>
      )}
    </div>
  );
};



export const DetailBoolItem = ({ label, value, TrueTxt, FalseTxt }) => {
  const color = value === "enabled"  ? "green" : "red";
  const text = value === "enabled" ? TrueTxt : FalseTxt;
  return (
    <div className=" mt-4 flex align-items-center ">
      <h6 className=" mt-0 mb-0 mr-1" style={{ fontSize: "15px" }}>
        <span>{label}</span>
      </h6>
      <Badge type={color}>{text}</Badge>
    </div>
  );
};

export const UserImgComponent = (props) => {
  const {
    firstName = " ",
    avatar = null,
  } = props;
  return (
    <div className=" h-9rem w-9rem m-auto mt-4 ">
      <UserImg
        src={`https://swilo.tn:3700/uploads/products/${avatar}`}
        name={firstName}
      />
    </div>
  );
};

export const SupplierProdMonthNumbers = (props) => {
  const { color, nbProds = 0, nbMonths = 0 } = props;
  return (
    <div className="flex align-items-center justify-content-around m-auto mt-6 ">
      <div className=" flex align-items-center justify-content-center ml-2 mr-2 ">
        <Avatar
          icon="pi pi-box"
          shape="square"
          size="large"
          className=" mr-2 "
          style={{
            color: color.txt,
            backgroundColor: color.bg,
          }}
        />
        <div className=" flex flex-column justify-content-start  ">
          <h5 className=" font-semibold mt-0 mb-0 ">{nbProds}</h5>
          <span className=" block ">Products</span>
        </div>
      </div>
      <div className=" flex align-items-center justify-content-center ml-2 mr-2 ">
        <Avatar
          icon="pi pi-calendar"
          shape="square"
          size="large"
          className=" mr-2 "
          style={{
            color: color.txt,
            backgroundColor: color.bg,
          }}
        />
        <div className=" flex flex-column justify-content-start  ">
          <h5 className=" font-semibold mt-0 mb-0 ">{nbMonths}</h5>
          <span className=" block ">Months</span>
        </div>
      </div>
    </div>
  );
};
