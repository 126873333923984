import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { GetAllPrestataire } from "../../../redux/prestataire/prestataire.actions";

import default_img from "../../../assets/images/default.jpg";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
const { REACT_APP_API_HOST } = process.env;


function Prestataires() {
  const prestataire = useSelector((state) => state.PrestataireReducer.prestataire);

  const dispatch = useDispatch()
  
  const reloadData = () => {
    dispatch(GetAllPrestataire());
  };

  useEffect(() => {
    reloadData();
  }, []);
  return (
    <>
      <div className="card">
        <h5>Recently Joined Cleaners</h5>
        {Array.isArray(prestataire) && prestataire.length > 0 ? (

        <DataTable value={prestataire} rows={10} paginator responsiveLayout="scroll">
          <Column
            header="Picture"
            body={(data) => (
              data?.profilePicture ? 
              <img                
                src={`${REACT_APP_API_HOST}/${data.profilePicture}`}
                width="40"
                height="40"

                style={{objectFit: "cover"}}
              />:
              <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '3.5rem',height: "3.5rem"}}><i className="pi pi-users text-orange-500 text-xl"></i></div>
            )}
          />
          <Column
            field="label"
            header="Name"
            sortable
            body={(data) => (
              <span

              >{data.username}</span>
            )}
            style={{ width: "35%" }}
          />
          <Column
            field="categorie"
            header="Phone"
            sortable
            body={(data) => (
              <span

              >{data.phone}</span>
            )}
            style={{ width: "25%" }}
          />
          <Column
            header="Date"
            style={{ width: "25%" }}
            body={(data) => (
              <span

              >{moment(data.createdAt).format("MMM Do YYYY")}</span>
            )}
          />
        </DataTable>
         ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
}


export default Prestataires;
