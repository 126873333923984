import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { GetAllProducts } from "../../../redux/products/product.actions";

import { useSelector, useDispatch } from "react-redux";

import Badge from "../../../MyComponents/DataDisplay/Badge";
import Pagination from "../../../MyComponents/Pagination/Pagination";

import AddProduct from "./popups/AddProduct";
import ShowProduct from "./popups/ShowProduct";

import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";
import FilterComp from "./Filter";

import { InitialState } from "../../../redux/university/university.reducer";
const { REACT_APP_API_HOST } = process.env;

const init_filter = {
  pays: "",
  approved: "",
  nom: "",
};

const GestUnivs = () => {
  let emptyItem = { ...InitialState };
  const product = useSelector((state) => state.ProductsReducer.product);

  const dispatch = useDispatch();

  const [Item, setItem] = useState(emptyItem);

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;

  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------
  const [addDialogue, setAddDialog] = useState(false);
  const [shoMDialogue, setShoMDialog] = useState(false);

  const dt = useRef(null);

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetAllProducts(Filter));
    
  };
  useEffect(() => {
    reloadData();
  }, [Filter]);

  //-------------------------------- Handle Opens -------------------------------

  const openAddDialogue = () => {
    setAddDialog(true);
  };
  const openShoMDialogue = (row) => {
    setShoMDialog(true);
    setItem({ ...row });
  };

  //-------------------------------- Handle Close -------------------------------
  const handleClose = () => {
    setItem({ ...emptyItem });
    setAddDialog(false);
    setShoMDialog(false);
  };

  //-------------------------------- Header of Page -------------------------------

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Products</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />

          <Button
            label="New"
            icon="pi pi-plus"
            tooltip="Reload Data"
            tooltipOptions={{ position: "bottom" }}
            className="p-button-primary mr-2"
            onClick={openAddDialogue}
          />
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const ShowMain = (row) => {
    return (
      <>   {Array.isArray(product) && product.length > 0 ? (

        <div className=" flex ">
  
          <AvatarComponent
                src={`${REACT_APP_API_HOST}/uploads/products/${row?.image}`}
                name={row?.productName}
          />
          <div className=" flex flex-column justify-content-center ml-2  ">
            <span className=" font-semibold ">{`${row.productName}`}</span>
            <span className=" text-600 ">
             
            </span>
          </div>
        
        </div>
        ) : (
          <p>No data available</p>
        )}</>
   
    );
  };

  const price = (row) => {
    return `${row?.price}`;
  };
  const type = (row) => {
    return `${row?.type}`;
  };
  const stock = (row) => {
    return `${row?.stock}`;
  };


  const actionBodyTemplate = (row) => {
    return (
      <div
        className="actions"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
       
        {row.numOfManagers === 0 ? (
         <></>
        ) : (
          <Button
            icon="pi pi-pencil"
            tooltip="Edit Product"
            tooltipOptions={{ position: "bottom" }}
            className="p-button-rounded p-button-text p-button-help mr-0"
            onClick={() => openShoMDialogue(row)}
          />
        )}       
        
      </div>
    );
  };
  
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />
          {Item && addDialogue && (
            <AddProduct
              open={addDialogue}
              handleClose={handleClose}
              title="Add Product"
              reloadData={reloadData}
            />
          )}



          {Item && shoMDialogue && (
            <ShowProduct
              open={shoMDialogue}
              handleClose={handleClose}
              value={Item}
              title={`${Item.productName}`}
              reloadData={reloadData}
            />
          )}
                  {Array.isArray(product) && product.length > 0 ? (

          <DataTable
            ref={dt}
            value={[...product]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No university found."
            // header={
            //   <FilterComp init_filter={init_filter} setFilter={setFilter} />
            // }
            responsiveLayout="scroll"
          >
            <Column header="Logo" body={ShowMain} />
           
            <Column field="Email" header="Type" body={type} />
            <Column field="Price" header="Price" body={price} />
            <Column field="Stock" header="Stock" body={stock} />

            <Column body={actionBodyTemplate} />
          </DataTable>
            ) : (
              <p>No data available</p>
            )}
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default GestUnivs;
