import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllTrans } from "../../../redux/transactions/transaction.actions";
import { GetAllPrestataire } from "../../../redux/prestataire/prestataire.actions";
import { GetAllClients } from "../../../redux/clients/clients.actions";

function HeadCards() {

  const transactions = useSelector((state) => state.transactionReducer.transactions);
  const prestataire = useSelector((state) => state.PrestataireReducer.prestataire);
  const clients = useSelector((state) => state.ClientReducer.client);


  const dispatch = useDispatch()

  const reloadData = () => {
  };

  
  
  const reloadDataStu = () => {
    dispatch(GetAllPrestataire());
  };
  const reloadDataClients = () => {
    dispatch(GetAllClients());
  };
  const reloadDatafEED = () => {
  };
  const reloadDataTransactions = () => {
    dispatch(GetAllTrans());
  };

  
  useEffect(() => {
    reloadData();
    reloadDataTransactions();
    reloadDataStu()
    reloadDataClients();
    reloadDatafEED()

  }, []);

  

  


  return (
    <>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Prestataires</span>
              <div className="text-900 font-medium text-xl">{prestataire.length}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-orange-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-users text-orange-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">on SWILO</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Clients</span>
              <div className="text-900 font-medium text-xl">{clients.length}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-green-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-fw pi-globe text-green-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">on SWILO</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                Transactions
              </span>
              <div className="text-900 font-medium text-xl">{transactions.length}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-money-bill text-cyan-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">sent today </span>
          <span className="text-500">on SWILO</span>
        </div>
      </div>
      {/*<div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Messages</span>
              <div className="text-900 font-medium text-xl">{conversations.length}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-purple-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-comment text-purple-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">newly sent </span>
          <span className="text-500">on SWILO</span>
        </div>
  </div>*/}
    </>
  );
}

export default HeadCards;
