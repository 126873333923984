const keys = {
    setUnivs: "setUnivs",
    setFormas: "setFormas",
    setDemands: "setDemands",
    payload: "university_payload",
    setSelUniv: "setSelUniv",
    setManager: "setManager",
    set_allManagers: "set_allManagers",
    all: "all_university",
  };
  
  export default keys;