import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import axios from "../../../../custom/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  UserImgComponent,
  DetailBoolItem,
  DetailItem,
} from "../../../../MyComponents/UserDisplay/SupplierCard";
import {GetOnePrestataire} from "../../../../redux/prestataire/prestataire.actions"

import { init_manager } from "../../../../redux/university/university.reducer";

function ShowManager(props) {

  const [price, setPrice] = useState(props.value.price || "Not Available");
  const [brand, setBrand] = useState(props.value.brand || "Not Available");
  const [shortDescription, setShortDescription] = useState(props.value.shortDescription || "Not Available");
  const [description, setDescription] = useState(props.value.description || "Not Available");
  const [stock, setStock] = useState(props.value.stock);
  const [title, setTitle] = useState(props.title || "Showing Manager");
  const [categories, setCategories] = useState(props.value.categories || []);
  const [newCategory, setNewCategory] = useState("");
  const { open, handleClose = "Showing Manager", value } = props;
  const manager = useSelector((state) => state.UniversityReducer.manager);
  const dispatch = useDispatch();
  const onePrestataire = useSelector(state => state.PrestataireReducer.onePrestataires);

  const updateProduct = async () => {
    try {
      const updatedProduct = await axios.put(`/products/updateProductById/${props.value._id}`, {
        productName : title,
        price,
        brand,
        shortDescription,
        description,
        stock,
        categories,
      });
      props.reloadData()
      props.handleClose()
    } catch (error) {
      console.error("Error updating product:", error);
    } finally{
      props.reloadData()
    }
  };
  const updateProductStatus = async (newStatus) => {
    try {
      const updatedProduct = await axios.put(`/products/updateProductById/${props.value._id}`, {
        status: newStatus // Only send the new status
      });
      props.reloadData()
      props.handleClose()
    } catch (error) {
      console.error("Error updating product status:", error);
    }
  };

  const handleAddCategory = (e) => {
    if (e.key === "Enter" && newCategory.trim() !== "") {
      setCategories([...categories, newCategory.trim()]);
      setNewCategory("");
    }
  };

  const { email, pays, suspended, tel, verified, firstName, lastName } =
    manager || init_manager;

  const GetManagerData = () => {
  };
  const fetchPrestataire = (value) => {
    dispatch(GetOnePrestataire(value));
  };

  //-------------------------------- Dialog States -------------------------------

  return (
    <Dialog
      visible={open}
      style={{ width: "80%" }}
      header={`Produit : `}
      modal
      className="p-fluid"
      onHide={handleClose}
    >

      <UserImgComponent
        firstName={title}
        avatar={props.value.image}
      />
      <div className="p-field">
        <label htmlFor="title" className="p-d-block">Title:</label>
        <input
          id="title"
          type="text"
          className="p-inputtext p-component"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      {/* <SupplierProdMonthNumbers color={color} nbProds={253} nbMonths={5} /> */}
      <h4 className=" text-800   mt-6 mb-0 ">Details</h4>
      <Divider className="mt-1 mb-1" />

      <DetailItem label="Price : " value={price} setValue={setPrice} />
      <DetailItem label="Brand : " value={brand} setValue={setBrand} />
      <DetailItem label="Short Description : " value={shortDescription} setValue={setShortDescription} />
      <DetailItem label="Description : " value={description} setValue={setDescription} />
      <div className="p-field">
        <label htmlFor="categories" className="p-d-block">
          Categories:
        </label>
        <div className="p-inputgroup">
          <input
            id="categories"
            type="text"
            className="p-inputtext p-component"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onKeyDown={handleAddCategory}
          />
          <Button
            icon="pi pi-plus"
            onClick={() => {
              if (newCategory.trim() !== "") {
                setCategories([...categories, newCategory.trim()]);
                setNewCategory("");
              }
            }}
          />
        </div>
        {/* Mapping categories */}
        <ul>
          {categories.map((category, index) => (
            <li key={index}>{category}</li>
          ))}
        </ul>
      </div>
      <DetailItem label="Stock : " value={stock} setValue={setStock} />
      <DetailBoolItem
        label="Status : "
        value={props.value.status}
        TrueTxt="enabled"
        FalseTxt="disabled"
      />

      

      <h4 className=" text-800   mt-4 mb-0 ">Actions For Product</h4>
      <Divider className="mt-1 mb-1" />

      <div className=" flex align-items-center justify-content-evenly mt-4 ">
        
        <Button // Disable Product
          icon="pi pi-pencil"
          label="Update Product"
          className="p-button-success mx-2"
          onClick={updateProduct}
        />
        <Button // Disable/Enable Product
          icon="pi pi-eye-slash"
          label={props.value.status === "enabled" ? "Disable Product" : "Enable Product"}
          className="p-button-danger mx-2"
          onClick={() => updateProductStatus(props.value.status === "enabled" ? "disabled" : "enabled")}
        />
      </div>
    </Dialog>
  );
}

export default ShowManager;
