import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { init_student } from "../../../../redux/students/student.reducer";
import BlockClient from "./BlockClient";

function ShowStudent(props) {
  const { open, handleClose, title = "Showing Student", value } = props;
  const {
    email,
    pays,
    suspended,
    tel,
    verified,
    username,
    passeport,
    CV,
    diplome,
    releveDeNote,
  } = value || init_student;

  //-------------------------------- Dialog States --------------------------------------------
  const [chosenRole, setChosenRole] = useState(null);

  const [blokDialogue, setBlokDialog] = useState(false);

  const openBlockDialogue = (role) => {
    setChosenRole(role);
    setBlokDialog(true);
  };
  const handleClose2 = () => {
    setBlokDialog(false);
    handleClose();
  };
  //-------------------------------- Dialog States -------------------------------

  return (
    <Dialog
      visible={open}
      style={{ width: "400px" }}
      header={title}
      modal
      className="p-fluid"
      onHide={handleClose}
    >
      <h4>
        Changer le role vers
      </h4>
      <div className=" flex align-items-center justify-content-evenly mt-4 ">
      <Button
        icon="pi pi-briefcase"
        label="Pro"
        className="p-button-success mx-2"
        onClick={() => openBlockDialogue("Pro")} // Wrap in arrow function
      />
      <Button
        icon="pi pi-shield"
        label="Admin"
        className="p-button-danger mx-2"
        onClick={openBlockDialogue.bind(null, "Admin")} // Use bind method
      />
      </div>
      {blokDialogue && (
        <BlockClient
          open={blokDialogue}
          handleClose={handleClose2}
          value={value}
          title={`Upgrade role`}
          chosenRole={chosenRole}
        />
      )}
    </Dialog>
  );
}

export default ShowStudent;
