import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Disconnect } from "../redux/user/user.actions";
//import { socket } from "../functions/socket.io";
export const AppTopbar = (props) => {

  const style={
    width:"20vw",
    height:"300px",
    overflowY:"scroll",
    borderRadius:"8px",
    backgroundColor:"white",
    position:"absolute",
    right:"5%",
    marginTop:"2.5rem",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important"
  }
  const styless= {
    width:"10px",
    height:"10px",
    overflowY:"scroll",
    borderRadius:"50%",
    backgroundColor:"red",
    position:"absolute",
    right:"5%",
    top:"10%"
  }
  const dispatch = useDispatch();
  const disconnect = () => {
    dispatch(Disconnect());
  };


  return (
    <div className="layout-topbar">
      

      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        <i className="pi pi-bars" />
      </button>
      <Link to="/" className="layout-topbar-logo">
         <img
          style={{width: "100px"}}
          src={"assets/layout/images/logo.svg"}
  
          alt="logo"
        /> 
      </Link>
      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <ul
        className={classNames("layout-topbar-menu lg:flex origin-top", {
          "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
        })}
        style={{position:"relative"}}
      >
        <li>
          <button className="p-link layout-topbar-button" onClick={disconnect}>
            <i className="pi pi-sign-out" />
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </div>
  );
};
