import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";
import axios from "../../../../custom/axios";
const { REACT_APP_API_HOST } = process.env;

function ShowCandidat(props) {
  const { open, handleClose, title = "Updating Item", value } = props;
  const payload = useSelector((state) => state.UniversityReducer.payload);

  const [item, setItem] = useState({ ...value });
  const dispatch = useDispatch();
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "700px";
      case "lg":
        return "700px";
      case "md":
        return "700px";
      case "sm":
        return "700px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  useEffect(() => {
    setItem({ ...value });
  }, [value]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  
  const updateProductStatus = async (newStatus) => {
    try {
      const updatedCandidature = await axios.put(`/candidatures/updateStatus`, {
        status: newStatus,
        candidatureId: item._id,
      });
    } catch (error) {
      console.error("Error updating product status:", error);
    }finally{
        handleClose();
        props.reloadData();
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Refuser"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={() => updateProductStatus("Refusé")} // Wrap the function call in an arrow function
        disabled={payload}
      />
      <Button
        label="Accepter"
        icon="pi pi-check"
        className=""
        onClick={() => updateProductStatus("Accepté")} // Wrap the function call in an arrow function
        disabled={payload}
      />
    </>
  );
  

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize() }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
      <div className="field col-12 md:col-12">
          <h6 style={{color : "#8dc040"}}>RNE</h6>
          <img style={{width:"100%" , height:"auto"}} src={`${REACT_APP_API_HOST}/${item?.latestRNE}`}/>
        </div>
        <div className="field col-12 md:col-12">
          <h6 style={{color : "#8dc040"}}>Business Name</h6>
          <h6>{item.businessName}</h6>
        </div>
        <div className="field col-12 md:col-12">
          <h6 style={{color : "#8dc040"}}>Region</h6>
          <h6>{item.regionAndCity}</h6>
        </div>
        <div className="field col-12 md:col-12">
          <h6 style={{color : "#8dc040"}}>Manager name</h6>
          <h6>{item.managerName}</h6>
        </div>
        <div className="field col-12 md:col-12">
          <h6 style={{color : "#8dc040"}}>Phone Number</h6>
          <h6>{item.phoneNumber}</h6>
        </div>
        <div className="field col-12 md:col-12">
          <h6 style={{color : "#8dc040"}} htmlFor="Email">Email</h6>
          <h6>{item.email}</h6>
        </div>
        <div className="field col-12 md:col-12">
          <h6 style={{color : "#8dc040"}}>RIB</h6>
          <h6>{item.RIB || "Not provided"}</h6>
        </div>
      </div>
    </Dialog>
  );
}

export default ShowCandidat;
