import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetAllStudents } from "../../../redux/students/student.actions";

import Pagination from "../../../MyComponents/Pagination/Pagination";

import ShowClient from "./popups/ShowClient";

import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";
import FilterComp from "./Filter";

import { init_student } from "../../../redux/students/student.reducer";

const init_filter = {
  // email:"",
  pays: "",
  suspended: "",
  verified: "",
};

const GestClients = () => {
  const students = useSelector((state) => state.StudentReducer.students);
  const dispatch = useDispatch();
  const histo = useHistory();

  const [Item, setItem] = useState({ ...init_student });

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;

  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------
  const [shoSDialogue, setShoSDialog] = useState(false);
  const dt = useRef(null);

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetAllStudents(Filter));
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);
  //-------------------------------- Handle Opens -------------------------------


  const openShoSDialogue = (row) => {
    setShoSDialog(true);
    setItem({ ...row });
  };

  //-------------------------------- Handle Close -------------------------------
  const handleClose = () => {
    setItem({ ...init_student });
    setShoSDialog(false);
    reloadData()

  };

  //-------------------------------- Header of Page -------------------------------

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Clients</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const ShowMain = (row) => {
    return (
      <div className=" flex ">
        <AvatarComponent
          src={null}
          circle={true}
          name={row?.username || "N"}
        />
        <div className=" flex flex-column justify-content-center ml-2  ">
          <span className=" font-semibold ">{` ${
            row?.username || "N"
          }`}</span>
          <span className=" text-600 ">{row?.email}</span>
        </div>
      </div>
    );
  };

  const PhoneColumnValue = (row) => {
    return row?.phone ? row?.phone : "Not Available";

  };

  const Pays = (row) => {
    return typeof row.orders === 'number' ? row.orders.toString() : "0";
  };


  const actionBodyTemplate = (row) => {
    return (
      <div
        className="actions"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
       {/* <Button
          icon="pi pi-ban"
          tooltip="Block Unblock"
          tooltipOptions={{ position: "bottom" }}
          className="p-button-rounded p-button-text p-button-warning mr-0"
          onClick={() => openBlockDialogue(row)}
        />*/}
       
        {<Button
          icon="pi pi-pencil"
          tooltip="Edit Role"
          tooltipOptions={{ position: "bottom" }}
          className="p-button-rounded p-button-text p-button-help mr-0"
          onClick={() => openShoSDialogue(row)}
        />}  
        
      </div>
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />


          {Item && shoSDialogue && (
            <ShowClient
              open={shoSDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Client ${Item.username}`}
            />
          )}

          <DataTable
            ref={dt}
            value={[...students]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Client found."
          
            responsiveLayout="scroll"
          >
            <Column header="Client" body={ShowMain} />
            <Column
              field="phoneNumber"
              header="Phone Number"
              body={PhoneColumnValue}
            />
            <Column field="Orders" header="Orders" body={Pays} />
            <Column body={actionBodyTemplate} />
          </DataTable>
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default GestClients;
