import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";
import InputFile from "../../../../MyComponents/Inputs/FileInput/InputFile";
import axios from "../../../../custom/axios";
import toast from "react-hot-toast";

const init_create = {
  productName: "",
  brand: "",
  shortDescription: "",
  description: "",
  price: "",
  type: "",
  stock: "",
  image: "",
  categories: "",
};

function AddSupplier(props) {
  const { open, handleClose, title = "Adding Item" } = props;
  const payload = useSelector((state) => state.UniversityReducer.payload);
  const [item, setItem] = useState({ ...init_create });
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const dispatch = useDispatch();
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  const handleAddCategory = () => {
    if (newCategory.trim() !== "") {
      const updatedCategories = [...categories, newCategory.trim()];
      handleCategoryChange(updatedCategories);
      setNewCategory("");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
    console.log({ ...item, [name]: value });
  };

  const handleCategoryChange = (updatedCategories) => {
    setCategories(updatedCategories);
    setItem({ ...item, categories: updatedCategories });
    console.log({ ...item, categories: updatedCategories });
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("productName", item.productName);
      formData.append("brand", item.brand);
      formData.append("shortDescription", item.shortDescription);
      formData.append("description", item.description);
      formData.append("price", item.price);
      formData.append("type", item.type);
      formData.append("stock", item.stock);
      formData.append("categories", item.categories.join(",")); // Join categories into a comma-separated string
      formData.append("image", item.image); // Assuming item.image holds the file object

      const response = await axios.post(`/products/createProduct`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type header
        },
      });
      props.reloadData();
      props.handleClose()
      toast.success(`Product Added Successfully`);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddCategory();
    }
  };

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize() }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Nom du produit*</label>
          <InputText
            name="productName"
            value={item.productName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="field col-12 md:col-12">
          <label>Marque*</label>
          <InputText
            name="brand"
            value={item.brand}
            onChange={handleChange}
            required
          />
        </div>
        <div className="field col-12 md:col-12">
          <label>Description courte*</label>
          <InputText
            name="shortDescription"
            value={item.shortDescription}
            onChange={handleChange}
            required
          />
        </div>
        <div className="field col-12 md:col-12">
          <label>Description*</label>
          <InputTextarea
            name="description"
            value={item.description}
            onChange={handleChange}
            required
            autoResize={false}
            rows="3"
            cols="30"
          />
        </div>
        <div className="p-field">
          <label htmlFor="categories" className="p-d-block">
            Categories:
          </label>
          <div className="p-inputgroup">
            <input
              id="categories"
              type="text"
              className="p-inputtext p-component"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button
              icon="pi pi-plus"
              onClick={handleAddCategory}
            />
          </div>
          {/* Mapping categories */}
          <ul>
            {categories.map((category, index) => (
              <li key={index}>{category}</li>
            ))}
          </ul>
        </div>
        <div className="field col-12 md:col-12">
          <label>Prix*</label>
          <InputText
            name="price"
            value={item.price}
            onChange={handleChange}
            required
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Image*</label>
          <InputFile
            placeholder="Upload Image"
            onChange={handleChange}
            name="image"
          />
          {item.image && (
            <small className="p-invalid">File name: {item.image.name}</small>
          )}
        </div>

        <div className="field col-12 md:col-12">
          <label>Stock*</label>
          <InputText
            name="stock"
            value={item.stock}
            onChange={handleChange}
          />
        </div>

        <div className="field col-12 md:col-12">
          <label htmlFor="description">Type*</label>
          <Dropdown
            value={item.type}
            name="type"
            onChange={handleChange}
            options={[
              { value: "produit", name: "Produit" },
              { value: "machine", name: "Machine" },
            ]}
            optionLabel="name"
            optionValue="value"
            placeholder="Select Type"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default AddSupplier;
