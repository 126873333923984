import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Chart } from "primereact/chart";
import { GetAllStudents } from "../../../redux/students/student.actions";
import { GetAllPrestataire } from "../../../redux/prestataire/prestataire.actions";

const ChartsOverview = () => {
  const dispatch = useDispatch();
  const students = useSelector((state) => state.StudentReducer.students);
  const prestataires = useSelector((state) => state.PrestataireReducer.prestataire);

  const [lineData, setLineData] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    datasets: [
      {
        label: "Prestataire",
        data: [],
        fill: false,
        backgroundColor: "#053162",
        borderColor: "#053162",
        tension: 0.4,
      },
      {
        label: "Clients",
        data: [],
        fill: false,
        backgroundColor: "#8DC040",
        borderColor: "#8DC040",
        tension: 0.4,
      },
    ],
  });

  useEffect(() => {
    dispatch(GetAllStudents({}));
    dispatch(GetAllPrestataire({}));
  }, [dispatch]);

  useEffect(() => {
    if (students.length && prestataires.length) {
      const processedData = processChartData(students, prestataires);
      setLineData(processedData);
    }
  }, [students, prestataires]);

  const processChartData = (students, prestataires) => {
    const labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const studentData = Array(12).fill(0); // Create an array for each month
    const prestataireData = Array(12).fill(0); // Create an array for each month

    const processData = (data, targetArray) => {
      data.forEach((user) => {
        const month = new Date(user.createdAt).getMonth();
        targetArray[month]++;
      });
    };

    processData(students, studentData);
    processData(prestataires, prestataireData);

    return {
      labels,
      datasets: [
        {
          label: "Prestataire",
          data: prestataireData,
          fill: false,
          backgroundColor: "#053162",
          borderColor: "#053162",
          tension: 0.4,
        },
        {
          label: "Clients",
          data: studentData,
          fill: false,
          backgroundColor: "#8DC040",
          borderColor: "#8DC040",
          tension: 0.4,
        },
      ],
    };
  };

  const light = {
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };

  return (
    <div className="card">
      <h5>Traffic Overview</h5>
      <Chart type="line" data={lineData} options={light} />
    </div>
  );
};

export default ChartsOverview;
