import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from "redux";

import thunk from "redux-thunk";

import { UserReducer } from "../user/user.reducer";
import { UniversityReducer } from "../university/university.reducer";
import { StudentReducer } from "../students/student.reducer";
import { PrestataireReducer } from "../prestataire/prestataire.reducer";
import { ClientReducer } from "../clients/clients.reducer";

import { SousAdminsReducer } from "../sousadmins/sousadmins.reducer";
import { ProductsReducer } from "../products/product.reducer";

import { transactionReducer } from "../transactions/transaction.reducer";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const allReducers = combineReducers({
  UserReducer,
  ClientReducer,
  PrestataireReducer,
  UniversityReducer,
  StudentReducer,
  ProductsReducer,
  SousAdminsReducer,
  transactionReducer
});

const store = createStore(allReducers, composeEnhancer(applyMiddleware(thunk)));

export { store };
