import React, { useState, useEffect, useRef } from "react";


import HeadCards from "./HeadCards";
import Prestataires from "./Prestataires";
import ChartsOverview from "./ChartsOverview";

const Dashboard = () => {
  // useEffect(() => {
  //   if (props.colorMode === "light") {
  //     applyLightTheme();
  //   } else {
  //     applyDarkTheme();
  //   }
  // }, [props.colorMode]);

  return (
    <div className="grid">
      <HeadCards />

      <div className="col-12 xl:col-6">
        <Prestataires />
      </div>

      <div className="col-12 xl:col-6">
        <ChartsOverview />
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};

export default React.memo(Dashboard, comparisonFn);
