import keys from "./transaction.keys";
import { toast } from "react-hot-toast";
// import { useSelector } from "react-redux";
import axios from "../../custom/axios";


const GetAllTrans = (query = {}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const today = new Date();
      const formattedDate = today.toISOString().slice(0, 10); // Format: YYYY-MM-DD

      const response = await axios.get(`orders/getAllExistingOrders`);

      // Filter transactions to include only those with createdAt date matching today's date
      const filteredTransactions = response.data.filter(transaction => {
        const transactionDate = transaction.createdAt.split(' ')[0]; // Assuming the date-time format is consistent
        return transactionDate === formattedDate;
      });

      dispatch({
        type: keys.set_transactions,
        value: filteredTransactions,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};


const GeneratePDF = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.get(
        `pdf/download/${id}`,
        { responseType: 'blob' } 
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.download = 'invoice.pdf';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

     console.log(response);
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const updateCommisionPrice = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.put(
        `/api/v1/university/commissionFormation/${user.Universite._id}/${user._id}`,
        user
      );
      console.log(keys.payload);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(`Formation Updated successfully`);
      window.location.reload()
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const notificationGetOwn = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.get(`/api/v1/notification/get-own-id`);
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      return response
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const DeleteStudentByAdmin = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.delete(`/api/v1/users/delete/${user.id}`);
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(`Student Deleted Successfully`);
      dispatch(GetAllTrans());
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const DeleteTransByAdmin = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.delete(`/api/v1/university/deletecFormation/${user._id}`);
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(`Student Deleted Successfully`);
      dispatch(GetAllTrans());
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const BlockUserByAdmin = (user, callback) => {
  const { suspended, id } = user;
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.put(`/api/v1/users/block-user/${id}`, {
        suspended: !suspended,
      });
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(
        `User ${!suspended ? "blocked" : "Ubblocked"} successfully`
      );
      dispatch(GetAllTrans());
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const UpdateStudentByAdmin = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.patch(
        `/api/v1/users/profile-by-admin/${user.id}`,
        {
          ...user,
        }
      );
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(`User Updated successfully`);
      dispatch(GetAllTrans());
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

export {
  BlockUserByAdmin,
  GeneratePDF,
  GetAllTrans,
  DeleteStudentByAdmin,
  notificationGetOwn,
  UpdateStudentByAdmin,
  updateCommisionPrice,
  DeleteTransByAdmin
};
