import keys from "./prestataire.keys";

export const init_prestataire = {
  id: "",
  role: "",
  email: "",
  firstName: "",
  lastName: "",
  tel: "",
  pays: "",
  verified: false,
  suspended: false,
  parcoursScolaire: [],
  requestDelete: false,
  experienceProfessionnelle: [],
  createdAt: new Date(),
};

export const InitialState = {
  payload: false,
  prestataires: [],
  prestataire: { ...init_prestataire },
  onePrestataires: {},
  candidatures: [], // Add candidatures state
};

export const PrestataireReducer = (state = { ...InitialState }, action) => {

  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.set_prestataire:
      return { ...state, prestataire: action.value, payload: false };
    case keys.payload:
      return { ...state, payload: action.value };
    case keys.set_onePrestataire:
      console.log("heeere", action.value);
      return { ...state, onePrestataires: action.value, payload: false };
      case keys.set_candidatures:
        return { ...state, candidatures: action.value, payload: false };
    default:
      return state;
  }
};
