import keys from "./transaction.keys";

export const init_transaction = {

  last_name : '',
  order_id : '',
  phone : '',
  first_name : '',
  amount : '',
  note : '',
   email : '',
  status : '',
  user : null,
  demand : null,
  univ : null,
  transaction : null,

};

export const InitialState = {
  payload: false,
  transactions: [],
  transaction: { ...init_transaction },
};

export const transactionReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.set_transaction:
      return { ...state, transaction: action.value, payload: false };
    case keys.payload:
      return { ...state, payload: action.value };
    case keys.set_transactions:
      return { ...state, transactions: action.value, payload: false };
    default:
      return state;
  }
};
