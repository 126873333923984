import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { GetAllCandidats } from "../../../redux/prestataire/prestataire.actions"; // Updated import

import { useSelector, useDispatch } from "react-redux";

import Badge from "../../../MyComponents/DataDisplay/Badge";
import Pagination from "../../../MyComponents/Pagination/Pagination";

import ShowCandidat from "./popups/ShowCandidat";
import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";

import { InitialState } from "../../../redux/university/university.reducer";
const { REACT_APP_API_HOST } = process.env;

const init_filter = {
  pays: "",
  approved: "",
  nom: "",
};

const CandidaturePage = () => {
  let emptyItem = { ...InitialState };
  const candidatures = useSelector((state) => state.PrestataireReducer.prestataire);
  const dispatch = useDispatch();

  const [Item, setItem] = useState(emptyItem);

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;

  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------
  const [showCandidature, setShowCandidature] = useState(false);

  const dt = useRef(null);

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetAllCandidats(Filter)); // Updated action call
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);
  //-------------------------------- Handle Opens -------------------------------

  const openShowCandidature = (row) => {
    setShowCandidature(true);
    setItem({ ...row });
  };
  

  //-------------------------------- Handle Close -------------------------------
  const handleClose = () => {
    setItem({ ...emptyItem });
    setShowCandidature(false);
  };

  //-------------------------------- Header of Page -------------------------------

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Gérer Candidatures</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />

        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const ShowMain = (row) => {
    console.log(row);
    return (
      <>   {Array.isArray(candidatures) && candidatures.length > 0 ? (

        <div className=" flex ">
  
          <AvatarComponent
                src={`${REACT_APP_API_HOST}/${row?.latestRNE}`}
                name={row?.businessName}
          />
          <div className=" flex flex-column justify-content-center ml-2  ">
            <span className=" font-semibold ">{`${row.businessName}`}</span>
            <span className=" text-600 ">
             
            </span>
          </div>
        
        </div>
        ) : (
          <p>No data available</p>
        )}</>
   
    );
  };

  const PhoneColumnValue = (row) => {
    return `${row?.phoneNumber}`;
  };
  const email = (row) => {
    return `${row?.email}`;
  };




  const IsApproved = (row) => {
    const color = row?.status === "Pending" ? "orange" : row?.status === "Refusé" ? "red" : "green";
    const text = row?.status
    return <Badge type={color}>{text}</Badge>;
  };

  const actionBodyTemplate = (row) => {
    return (
      <div
        className="actions"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {row.status === "Pending" && (
          
          <Button
            icon="pi pi-check-square"
            tooltip="Consulter"
            tooltipOptions={{ position: "bottom" }}
            className="p-button-rounded p-button-text p-button-success mr-0"
            onClick={() => openShowCandidature(row)}
          />
          
        )}
      

       
        
      </div>
    );
  };
  
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />
          
          {Item && showCandidature && (
            <ShowCandidat
              open={showCandidature}
              handleClose={handleClose}
              value={Item}
              title={`Consulter Candidature`}
              reloadData={reloadData}
            />
          )}
          

                  {Array.isArray(candidatures) && candidatures.length > 0 ? (

          <DataTable
            ref={dt}
            value={[...candidatures]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Applications found."
            
            responsiveLayout="scroll"
          >
            <Column header="Logo" body={ShowMain} />
            <Column
              field="phoneNumber"
              header="Phone Number"
              body={PhoneColumnValue}
            />
            <Column field="Email" header="Email" body={email} />
            <Column field="suspended" header="Status" body={IsApproved} />
            <Column body={actionBodyTemplate} />
          </DataTable>
            ) : (
              <p>No data available</p>
            )}
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default CandidaturePage;
