import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { GetAllPrestataire } from "../../../redux/prestataire/prestataire.actions";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Badge from "../../../MyComponents/DataDisplay/Badge";
import Pagination from "../../../MyComponents/Pagination/Pagination";

import AddUniv from "./popups/AddUniv";
import UpdateUniv from "./popups/UpdateUniv";
import DelUniv from "./popups/DelUniv";
import ApproveUniv from "./popups/ApproveUniv";
import AddManager from "./popups/AddManager";
import ShowManager from "./popups/ShowManager";

import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";
import FilterComp from "./Filter";

import { InitialState } from "../../../redux/university/university.reducer";
const { REACT_APP_API_HOST } = process.env;

const init_filter = {
  pays: "",
  approved: "",
  nom: "",
};

const GestCleaners = () => {
  let emptyItem = { ...InitialState };
  const prestataire = useSelector((state) => state.PrestataireReducer.prestataire);

  const univs = useSelector((state) => state.UniversityReducer.universitys);
  const dispatch = useDispatch();
  const histo = useHistory();

  const [Item, setItem] = useState(emptyItem);

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;

  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------
  const [addDialogue, setAddDialog] = useState(false);
  const [updDialogue, setUpdDialog] = useState(false);
  const [suppDialogue, setSuppDialog] = useState(false);
  const [appDialogue, setAppDialog] = useState(false);
  const [shoMDialogue, setShoMDialog] = useState(false);

  const [addMDialogue, setAddMDialog] = useState(false);

  const dt = useRef(null);

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetAllPrestataire(Filter));
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);
  //-------------------------------- Handle Opens -------------------------------

  const openAddDialogue = () => {
    setAddDialog(true);
  };
  const openUpdDialogue = (row) => {
    setUpdDialog(true);
    setItem({ ...row });
  };
  const openSuppDialogue = (row) => {
    setSuppDialog(true);
    setItem({ ...row });
  };
  const openAppDialogue = (row) => {
    setAppDialog(true);
    setItem({ ...row });
  };
  const openAddMDialogue = (row) => {
    setAddMDialog(true);
    setItem({ ...row });
  };
  const openShoMDialogue = (row) => {
    setShoMDialog(true);
    setItem({ ...row });
  };

  //-------------------------------- Handle Close -------------------------------
  const handleClose = () => {
    setItem({ ...emptyItem });
    setAddDialog(false);
    setUpdDialog(false);
    setSuppDialog(false);
    setAddMDialog(false);
    setShoMDialog(false);
    setAppDialog(false);
    reloadData()
  };

  //-------------------------------- Header of Page -------------------------------

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Cleaners</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />

        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const ShowMain = (row) => {
    return (
      <>   {Array.isArray(prestataire) && prestataire.length > 0 ? (

        <div className=" flex ">
  
          <AvatarComponent
                src={`${REACT_APP_API_HOST}/${row?.profilePicture}`}
                name={row?.profilePicture}
          />
          <div className=" flex flex-column justify-content-center ml-2  ">
            <span className=" font-semibold ">{`${row.username}`}</span>
            <span className=" text-600 ">
             
            </span>
          </div>
        
        </div>
        ) : (
          <p>No data available</p>
        )}</>
   
    );
  };

  const PhoneColumnValue = (row) => {
    return `${row?.phone}`;
  };
  const email = (row) => {
    return `${row?.email}`;
  };

  const IsSuspended = (row) => {
    const color = row?.status ? "green" : "red";
    const text = row?.status ? "On" : "OFF";
    return <Badge type={color}>{text}</Badge>;
  };


  const IsApproved = (row) => {
    const color = row?.storeStatus ? "green" : "red";
    const text = row?.storeStatus ? "On" : "OFF";
    return <Badge type={color}>{text}</Badge>;
  };

  const actionBodyTemplate = (row) => {
    return (
      <div
        className="actions"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
          <Button
            icon="pi pi-check-square"
            tooltip={`${row.status ? "Block" : "Unblock"} user`}
            tooltipOptions={{ position: "bottom" }}
            className="p-button-rounded p-button-text p-button-success mr-0"
            onClick={() => openAppDialogue(row)}
          />
       
      
        

       
        <Button
          icon="pi pi-trash"
          tooltip="Delete Prestataire"
          tooltipOptions={{ position: "bottom" }}
          className="p-button-rounded p-button-text p-button-warning mr-0"
          onClick={() => openSuppDialogue(row)}
        />
      </div>
    );
  };
  
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />
          {Item && addDialogue && (
            <AddUniv
              open={addDialogue}
              handleClose={handleClose}
              title="Add New University"
            />
          )}

          {Item && updDialogue && (
            <UpdateUniv
              open={updDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Updating the university ${Item.nom}`}
              reloadData={reloadData}
            />
          )}

          {Item && suppDialogue && (
            <DelUniv
              open={suppDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Supprimer le Prestataire ${Item.username}`}
            />
          )}

          {Item && appDialogue && (
            <ApproveUniv
              open={appDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Change User ${Item.username} Status`}
            />
          )}

          {Item && addMDialogue && (
            <AddManager
              open={addMDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Add Manager of the university ${Item.nom}`}
            />
          )}

          {Item && shoMDialogue && (
            <ShowManager
              open={shoMDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Prestataire :  `}
            />
          )}
                  {Array.isArray(prestataire) && prestataire.length > 0 ? (

          <DataTable
            ref={dt}
            value={[...prestataire]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Cleaners found."
            
            responsiveLayout="scroll"
          >
            <Column header="Logo" body={ShowMain} />
            <Column
              field="phoneNumber"
              header="Phone Number"
              body={PhoneColumnValue}
            />
            <Column field="suspended" header="Status" body={IsSuspended} />
            <Column body={actionBodyTemplate} />
          </DataTable>
            ) : (
              <p>No data available</p>
            )}
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default GestCleaners;
