import keys from "./product.keys";
import { toast } from "react-hot-toast";
// import { useSelector } from "react-redux";
import axios from "../../custom/axios";
import { InitialState, init_student } from "./product.reducer";
import { Mquery } from "../../functions/MakeQuery";

const GetAllProducts = (query = {}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.get("products/getAllProducts");

      dispatch({
        type: keys.set_product,
        value: response.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const GetOnePrestataire = (query = {}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.get(
        `crudRouter/${query._id}?modelName=User`,
        Mquery(query)
      );
      console.log("***********",query);
      dispatch({
        type: keys.set_onePrestataire,
        value: response.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};


const DeleteProduct = (query = {}, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.delete(`crudRouter/${query._id}?modelName=Product`);
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(`Student Deleted Successfully`);
      dispatch(GetAllProducts());
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const BlockUserByAdmin = (user, callback) => {
  const { suspended, id } = user;
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.put(`/api/v1/users/block-user/${id}`, {
        suspended: !suspended,
      });
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(
        `User ${!suspended ? "blocked" : "Ubblocked"} successfully`
      );
      dispatch(GetAllProducts());
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const UpdateStudentByAdmin = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.patch(
        `/api/v1/users/profile-by-admin/${user.id}`,
        {
          ...user,
        }
      );
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(`User Updated successfully`);
      dispatch(GetAllProducts());
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

export {
  BlockUserByAdmin,
  GetAllProducts,
  DeleteProduct,
  UpdateStudentByAdmin,
  GetOnePrestataire,
};
