export const isUser = (user) => {
  return user?.type === "user";
};

export const isResp = (user) => {
  return user?.type === "user";
};

export const isSousAdmin = (user) => {
  return user?.userType === "Sous-Admin";
};

export const isSuperAdmin = (user) => {
  return user?.userType === "Admin";
};
